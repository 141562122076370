import React, {useRef} from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import {Container, Row, Col} from "react-bootstrap"
import SEO from "../components/seo"
import {Helmet} from "react-helmet"

export default function Home() {

    const [email, setEmail] = React.useState('');

    const goTrial = () => {
        window.location.href = 'https://try.inspectionsupport.com?email=' + email;
    };

    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const videoRef3 = useRef(null);
    const videoRef1MD = useRef(null);
    const videoRef2MD = useRef(null);
    const videoRef3MD = useRef(null);
    const videoRef1SM = useRef(null);
    const videoRef2SM = useRef(null);
    const videoRef3SM = useRef(null);

    const handlePlay1 = () => {
        videoRef1.current.play();
        videoRef1.current.controls = true;
    }
    const handlePlay2 = () => {
        videoRef2.current.play();
        videoRef2.current.controls = true;
    }
    const handlePlay3 = () => {
        videoRef3.current.play();
        videoRef3.current.controls = true;
    }

    const handlePlay1MD = () => {
        videoRef1MD.current.play();
        videoRef1MD.current.controls = true;
    }
    const handlePlay2MD = () => {
        videoRef2MD.current.play();
        videoRef2MD.current.controls = true;
    }
    const handlePlay3MD = () => {
        videoRef3MD.current.play();
        videoRef3MD.current.controls = true;
    }

    const handlePlay1SM = () => {
        videoRef1SM.current.play();
        videoRef1SM.current.controls = true;
    }
    const handlePlay2SM = () => {
        videoRef2SM.current.play();
        videoRef2SM.current.controls = true;
    }
    const handlePlay3SM = () => {
        videoRef3SM.current.play();
        videoRef3SM.current.controls = true;
    }

    return (
        <React.Fragment>
            <Helmet
                title="ISN Home Inspection Software - Trusted With Millions of Inspections"
                defer={false}
            />

            <SEO 
                title='ISN Home Inspection Software - Trusted With Millions of Inspections' 
                description='Write interactive home inspection reports, create professional websites, automate communication and scheduling, and much more with ISN home inspection software.'
            />

            <Container>
                <Header activepage="index"/>
            </Container>

            <Container fluid className={'container-root'}>
                <Row>
                    <section className={'hero'}>
                        <div className={'hero-content'} >
                            <h1 className="headerreplacement-mobile white text-center mb-4 p-0">
                                Easy-to-use home inspection software to grow a stronger business.
                            </h1>

                            <div className="mb-5">
                                <p className="subhead-larger-mobile">
                                    With ISN home inspection software, you can  write interactive inspection reports, manage your business, create professional websites, and automate communication with customers, staff, and real estate agents.
                                </p>
                            </div>

                            <div className="mb-3">
                                <p className="start-free-no-contract my-0">
                                    Trusted with over 1 million home inspections per year
                                </p>
                                <p className="start-free-no-contract-mobile my-0">
                                    Trusted with over 1 million home inspections per year
                                </p>
                            </div>

                            <div className="mb-3">
                                <p className="start-free-no-contract my-0">
                                    No contracts, no hidden fees, cancel anytime
                                </p>
                                <p className="start-free-no-contract-mobile my-0">
                                    No contracts, no hidden fees, cancel anytime
                                </p>
                            </div>
                            <div className="start-free text-center">
                                <div>
                                    <button className="free-trial btn blue-button button" onClick={goTrial}>
                                        TRY ISN FREE FOR 30 DAYS
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Row>

                <Row>
                    <Col md={12} xs={12}>

                        <img
                            className="img-central-computer"
                            src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/Group.png'}
                            alt="ISN main computer with map"
                        />

                        <h2 className="subheaderreplacement mt-4">WHY YOU NEED ISN HOME INSPECTION SOFTWARE</h2>

                        {/*<h2 className="headerreplacement2 mt-2">Less busy work.</h2>*/}

                        <p className="software-that-helps mt-3">
                            Whether you’re a new or seasoned home inspection professional, ISN home inspection software delivers a fast and reliable platform that scales to meet your changing business needs.
                        </p>

                        <div style={{margin: "20px 0"}}>&nbsp;</div>

                        <Container>
                            <Row className="justify-content-center">
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_report_writer@1.5x.png'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Report Writer (NEW!)</h4>
                                    <p className="feat-text">ISN now includes a flexible home inspection report-writing tool featuring fast, intuitive data entry, voice command and recording, photo and video editing capabilities, live data sync, and interactive report delivery</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_time@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Increased productivity</h4>
                                    <p className="feat-text">Easy appointment scheduling, report delivery, job status tracking, and mobile capabilities</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_paid@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Scalable, usage-based pricing</h4>
                                    <p className="feat-text">Pay only for what you use based on your inspection volume, no charge for additional users</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_track@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Real-time business insights</h4>
                                    <p className="feat-text">Pull quarterly financials, reports showing which agents send the most business, and more.</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_insight@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Effective agent & client communication</h4>
                                    <p className="feat-text">Enhanced email & text tools, messaging templates, and appointment reminders.</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_support@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Live text, phone and chat support</h4>
                                    <p className="feat-text">Inspection experts available 7 days a week.</p>
                                </Col>
                            </Row>
                        </Container>

                        <Container>
                            <Row className="justify-content-center">
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_integrations@1.5x.jpg'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Flexibility</h4>
                                    <p className="feat-text">Seamless integration with leading report writers (Home Inspector Pro, Horizon, HomeGauge, and more).</p>
                                </Col>
                                <Col md={4} xs={6} className="py-4">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/icon_professional_website@1.5x.png'}
                                         width="51" height="51" className="p-0" alt="" style={{width: "51px"}}/>
                                    <h4 className="feat-header-text py-3 m-0">Professional websites (NEW!)</h4>
                                    <p className="feat-text">Let ISN build and maintain a website featuring up to five pages to give your inspection business the web presence it deserves.</p>
                                </Col>

                                <Col md={4} xs={6} className="py-4">
                                    <h4 className="feat-header-text py-3 m-0">&nbsp;</h4>
                                    <p className="feat-text">&nbsp;</p>
                                </Col>

                            </Row>
                        </Container>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <a href="/features" className="all-topic mx-auto p-4 mb-5">
                            ALL FEATURES
                        </a>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                    </Col>
                </Row>
                <Row style={{backgroundColor: "#F9F9F9"}}>
                    <Container>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <h2 className="subheaderreplacement mt-5">ISN REVIEWS</h2>

                        <h2 className="headerreplacement2 mt-3">Here’s what our<br/> customers have to say.</h2>

                        <div style={{margin: "20px auto"}}>&nbsp;</div>

                        <div id="reviewsio-carousel-widget"/>

                        <div style={{margin: "20px auto"}}>&nbsp;</div>

                        <h2 className="subheaderreplacement mt-5">ISN HOME INSPECTION SOFTWARE IS TRUSTED BY BUSINESS OWNERS NATIONWIDE</h2>

                        <Row className="justify-content-center" style={{marginTop: '100px'}}>
                            <Col md={4} xs={10} className="p-0">
                                <div className={'facts facts-1'}>
                                    <p className="facts-number-mobile" style={{color: "#0070EB"}}>25 years</p>
                                    <p className="facts-text-mobile">Helping inspectors streamline and grow.</p>
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/illustration1%401.5x.jpg'}
                                         style={{width: '177px'}}/>
                                </div>
                            </Col>
                            <Col md={4} xs={10} className="p-0">
                                <div className={'facts facts-2'}>
                                    <p className="facts-number-mobile" style={{color: "#23974F"}}>2 million</p>
                                    <p className="facts-text-mobile">Inspections processed through ISN in 2021.</p>
                                    <img src={'https://s3.us-west-2.amazonaws.com/www.inspectionsupport.com-2021/images-2021/illustration2%401.5x.jpg'}
                                         style={{width: '125px'}}/>
                                </div>
                            </Col>
                            <Col md={4} xs={10} className="p-0">
                                <div className={'facts facts-3'}>
                                    <p className="facts-number-mobile" style={{color: "#3D1681"}}>Highly Rated</p>
                                    <p className="facts-text-mobile">By Capterra and GetApp.</p>

                                    <a href={'https://www.capterra.com/p/144012/Inspection-Support-Network/#about'} target={'_blank'}>
                                        <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/capterrabestvalue@1.5x.jpg'} style={{width: "77px"}} alt=""/>
                                    </a>

                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/getapp@1.5x.jpg'}
                                         style={{width: "105px", marginLeft: "20px"}} alt=""/>
                                </div>
                            </Col>
                        </Row>

                        <div style={{margin: "20px auto"}}>&nbsp;</div>

                        <a href="https://www.reviews.io/company-reviews/store/www.inspectionsupport.net"
                           rel={'external' +
                               ' nofollow'} target={'_blank'} className="all-topic mx-auto p-4 mb-5">
                            ALL REVIEWS
                        </a>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                    </Container>
                </Row>
                <Row>
                    <Container>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <h3 className="subheaderreplacement mt-5">PRICING</h3>

                        <p className="software-that-helps mt-3">Simple pricing that makes sense. Pay only for the inspections you need, don’t pay for what you don’t use. Reduce overhead in a model that costs less as you grow.</p>

                        <Row className="justify-content-center" style={{marginTop: '100px'}}>
                            <Col xs={12} md={4} style={{verticalAlign: 'top', paddingTop: '0'}}>
                                <div style={{backgroundColor: "#21984E", borderRadius: "1rem"}} className="pricing">
                                    <p className="center-text p-2">
                                        <span className="pricing-dollar">$</span><span
                                        className="pricing-amount">6</span><br/>
                                    </p>
                                    <p className="pricing-text px-2"><span
                                        className="pricing-text-bold">per inspection</span> for the first 50 inspections
                                        per month</p>
                                </div>
                            </Col>
                            <Col xs={12} md={4} style={{verticalAlign: 'top', paddingTop: '0'}}>
                                <div style={{backgroundColor: "#0070EB", borderRadius: "1rem"}} className="pricing">
                                    <p className="center-text p-2">
                                        <span className="pricing-dollar">$</span><span
                                        className="pricing-amount">4</span><span
                                        className="pricing-dollar">50</span><br/>
                                    </p>
                                    <p className="pricing-text px-2">for the <span className="pricing-text-bold">next 50 inspections</span> within
                                        the same month</p>
                                </div>
                            </Col>
                            <Col xs={12} md={4} style={{verticalAlign: 'top', paddingTop: '0'}}>
                                <div style={{backgroundColor: "#3D1681", borderRadius: "1rem"}} className="pricing">
                                    <p className="center-text p-2">
                                        <span className="pricing-dollar">$</span><span
                                        className="pricing-amount">3</span><br/>
                                    </p>
                                    <p className="pricing-text px-2"><span
                                        className="pricing-text-bold">for the rest</span> of your inspections that month
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <p className="software-that-helps mt-3">Get started with ISN completely for free, for 30 days. Automate, streamline and connect your business today!</p>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <a href="/pricing" className="all-topic mx-auto p-4 mb-5" style={{width:'220px'}}>
                            PRICING DETAILS
                        </a>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                    </Container>
                </Row>
                <Row style={{backgroundColor: "#F9F9F9"}}>
                    <Container>
                        <Row style={{margin: '80px 0'}}>
                            <Col xs={12} className={'one-on-one-support-guy-mobile hidden-not-mobile'}>
                                <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/supportimage@1.5x.jpg'}
                                     alt=""/>
                            </Col>
                            <Col xs={12} md={6} className={'one-on-one-support'} >
                                <div style={{margin:'0px auto', textAlign:'center'}} className="centeradjustment">
                                    <h2 className="subheaderreplacement text-left mt-5 centeradjustment">LIVE SUPPORT</h2>

                                    <p className="support-text-mobile my-4" style={{ paddingBottom:'60px'}}>We offer free <span
                                        className="blue text-bold">live</span> phone, chat and email support, as well as 1:1 training and group webinars to help you every step of the way. Our support team has a <span
                                        className="blue text-bold">96% Customer Satisfaction Rating</span>.
                                    </p>
                                    <div>
                                        <a href="/contact" className="all-topic mx-0 p-4 mb-5 " style={{display:'inline'}}>
                                            GET IN TOUCH
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className={'one-on-one-support-guy'}>
                                <div className="centeradjustment">
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/supportimage@1.5x.jpg'}
                                         alt=""/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{backgroundColor: "#3D1681", paddingBottom: '40px'}}>
                    <Container>
                        <div style={{margin: "40px auto"}}>&nbsp;</div>
                        <h2 className="testimonial-header-mobile">TESTIMONIALS</h2>

                        <h2 className="why-inspectors-love-mobile">
                            Why home inspectors love ISN home inspection software.
                        </h2>
                        <div style={{margin: "90px auto"}}>&nbsp;</div>
                    </Container>
                </Row>
                <Row className={'testimonials-mobile'} style={{position: 'relative', top: '-150px'}}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={4}>
                                <div className="centeradjustment">
                                    <video style={{width: '354px', height: '245px', objectFit: 'cover'}}
                                           poster={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/thumbnail1@1.5x.jpg'}
                                           ref={videoRef1SM} onClick={handlePlay1SM}>
                                        <source
                                            src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/videos/iilag.mp4"
                                            type="video/mp4"
                                        />
                                        <track
                                            kind="captions"
                                            src=""
                                            srcLang="en"
                                            label="No Captions Available"
                                        />
                                    </video>
                                    <p className="videotext-mobile">
                                        "With ISN, we saw an opportunity to have someone else do the work that was driving
                                        us crazy"
                                    </p>
                                    <p className="video-subtext-mobile mb-0 mt-4">
                                        Pam Pybas
                                    </p>
                                    <p className="video-company-mobile mx-0 my-1">
                                        Inspect It Like A Girl
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="centeradjustment">
                                    <video style={{width: '354px', height: '245px', objectFit: 'cover'}}
                                           poster={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/thumbnail2@1.5x.jpg'}
                                           ref={videoRef2SM} onClick={handlePlay2SM}>
                                        <source
                                            src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/videos/coastal.mp4"
                                            type="video/mp4"
                                        />
                                        <track
                                            kind="captions"
                                            src=""
                                            srcLang="en"
                                            label="No Captions Available"
                                        />
                                    </video>
                                    <p className="videotext-mobile">
                                        "ISN integrates several aspects of inspection scheduling and makes things much
                                        easier and central so
                                        that your job as an inspector is significantly better"
                                    </p>
                                    <p className="video-subtext-mobile mb-0 mt-4">Jeff Kirkpatrick</p>
                                    <p className="video-company-mobile mx-0 my-1">Coastal Inspection Services</p>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="centeradjustment">
                                    <video style={{width: '354px', height: '245px', objectFit: 'cover'}}
                                           poster={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/thumbnail3@1.5x.jpg'}
                                           ref={videoRef3SM} onClick={handlePlay3SM}>
                                        <source
                                            src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/videos/4corners.mp4"
                                            type="video/mp4"
                                        />
                                        <track
                                            kind="captions"
                                            src=""
                                            srcLang="en"
                                            label="No Captions Available"
                                        />
                                    </video>

                                    <p className="videotext-mobile">
                                        "[ISN] is so organized, so comprehensive, and is a system that allows you to grow as
                                        an inspector"
                                    </p>
                                    <p className="video-subtext-mobile mb-0 mt-4">Barry Mankin</p>
                                    <p className="video-company-mobile mx-0 my-1">4 Corners Home Inspections</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </Row>
                <Row style={{backgroundColor: "#F9F9F9"}}>
                    <Container>
                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <h2 className="subheaderreplacement">HOME INSPECTION TOOLS AND RESOURCES</h2>

                        <h3 className="headerreplacement2 mt-3">Information to help you along the way.</h3>

                        <div style={{margin: "30px auto"}}>&nbsp;</div>

                        <Row className="resources-mobile justify-content-center">
                            <Col xs={12} md={4}>
                                <a href={'https://www.inspectionsupport.com/resources/real-estate-attorneys-what-buyers-sellers-should-know/'} target={'_blank'}>
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/image1@1.5x.jpg'}
                                         alt=""/>
                                    <p className="m-0 p-0 resources-date">June 3rd, 2021</p>
                                    <h3 className="resources-header">Real Estate Attorneys: What Buyers and Sellers Should
                                        Know</h3>
                                </a>
                            </Col>
                            <Col xs={12} md={4}>
                                <a href={'https://www.inspectionsupport.com/resources/what-is-a-roofing-inspection/'} target={'_blank'}>
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/image2@1.5x.jpg'}
                                         alt=""/>
                                    <p className="m-0 p-0 resources-date">June 7th, 2021</p>
                                    <h3 className="resources-header">What is a Roofing Inspection, and Why is it
                                        Important?</h3>
                                </a>
                            </Col>
                            <Col xs={12} md={4}>
                                <a href={'https://www.inspectionsupport.com/resources/home-appraisal-101-maximizing/'} target={'_blank'}>
                                    <img src={'https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images-2021/image3@1.5x.jpg'}
                                         alt=""/>
                                    <p className="m-0 p-0 resources-date">May 6th, 2021</p>
                                    <h3 className="resources-header">Home Appraisal 101: Maximizing the Value of Your
                                        Home</h3>
                                </a>
                            </Col>
                        </Row>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                    </Container>

                </Row>
                <Row>
                    <Container>
                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                        <h3 className="subheaderreplacement">JOIN THE INSPECTION SUPPORT NETWORK FAMILY</h3>

                        <h2 className="headerreplacement2 mt-3 mb-0">
                            No contract. No Setup fee.
                        </h2>
                        <h2 className={'headerreplacement2'}>
                            Cancel Anytime.
                        </h2>

                        <div className="text-center mt-5">
                            <a href="https://try.inspectionsupport.com/" className="button blue-button"
                               style={{
                                   display: 'block',
                                   margin: '0 auto',
                                   width: '191px',
                                   height: 'auto',
                                   padding: '20px'
                               }}
                            >GIVE&nbsp;US&nbsp;A&nbsp;TRY</a>
                        </div>

                        <div style={{margin: "40px auto"}}>&nbsp;</div>

                    </Container>
                </Row>

            </Container>


            <Footer/>
        </React.Fragment>
    );
};
